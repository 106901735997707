import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";

import { Company } from "@/types/company";
import { ApiResponse, apiGet, apiPost } from "@/utils/api";

export type AuthenticationContext = {
  company: Company | null;
  login: (
    code: string,
    password: string,
  ) => Promise<ApiResponse<Company | unknown>>;
  logout: () => Promise<ApiResponse<null>>;
};

export const AuthenticationContext = createContext<AuthenticationContext>(
  {} as AuthenticationContext,
);

export const Authentication = ({ children }: { children: ReactNode }) => {
  const [loggedInCompany, setLoggedInCompany] = useState<Company | null>();

  const fetch = useCallback(async () => {
    const { data } = await apiGet<Company | null>("/api/me");

    return data;
  }, []);

  const login = useCallback(async (code: string, password: string) => {
    const loginResponse = await apiPost<Company>("/api/login", {
      code,
      password,
    });

    if (loginResponse.success) {
      setLoggedInCompany(await fetch());
    } else {
      setLoggedInCompany(null);
    }

    return loginResponse;
  }, []);

  const logout = useCallback(async () => {
    const logoutResponse = await apiPost<null>("/api/logout");

    if (logoutResponse.success) {
      setLoggedInCompany(null);
    }

    return logoutResponse;
  }, []);

  useEffect(() => {
    (async () => setLoggedInCompany(await fetch()))();
  }, []);

  return loggedInCompany !== undefined ? (
    <AuthenticationContext.Provider
      value={{ company: loggedInCompany, login, logout }}
    >
      {children}
    </AuthenticationContext.Provider>
  ) : (
    <></>
  );
};

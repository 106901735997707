import { HTMLAttributes, useState } from "react";

import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { IoMdCalendar } from "react-icons/io";
import "react-datepicker/dist/react-datepicker.css";

type Props = Partial<ReactDatePickerProps> & {
  defaultValue?: Date;
  wrapperProps?: HTMLAttributes<HTMLDivElement>;
  className?: string;
  setTimeStamp: (timestamp: Date) => void;
};
export const TimePicker = ({
  defaultValue,
  wrapperProps,
  setTimeStamp,
  className,
  ...datePickerProps
}: Props) => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    defaultValue,
  );

  const handleChange = (date: Date) => {
    if (!date) return;

    setSelectedDate(date);
    setTimeStamp(date);
  };

  return (
    <div {...(wrapperProps || {})}>
      <DatePicker
        dateFormatCalendar='yyyy年MM月dd日HH:mm'
        dateFormat='yyyy/MM/dd HH:mm'
        selected={selectedDate}
        onChange={handleChange}
        showTimeSelect
        timeIntervals={1}
        className={`h-8 w-[100%] rounded-sm border border-black-light pl-7 text-[14px] text-primary-deep ${className}`}
        {...datePickerProps}
      />
      <IoMdCalendar className='relative top-[-25px] ml-2 text-primary-deep' />
    </div>
  );
};

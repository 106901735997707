import { ChangeEvent, useCallback, useMemo, useRef, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { convert, detect } from "encoding-japanese";
import { parse } from "papaparse";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";

import {
  BoundaryOfStopOverColumnIndex,
  DailyReportColumnIndexes,
  DefaultSimulationValues,
  InitialDailyReport,
  DailyReportCsvRow,
} from "@/components/simulation";
import { Paths, Species } from "@/constants";
import {
  chunk,
  toBoolean,
  toDate,
  toHalfWidth,
  toNumber,
  toSeconds,
} from "@/utils/convert";
import { openWindowWithSession } from "@/utils/window";

const dailyReportSchema = yup.object({
  vehicleId: yup
    .number()
    .nullable()
    .transform((value: number) => (isNaN(value) ? null : value)),
  leavingFrom: yup
    .string()
    .nullable()
    .test({
      message: "出発地には緯度・経度を入力してください",
      test: (value) => !!value && value.split(",").filter(Boolean).length === 2,
    }),
  arrivingTo: yup
    .string()
    .nullable()
    .test({
      message: "目的地には緯度・経度を入力してください",
      test: (value) => !!value && value.split(",").filter(Boolean).length === 2,
    }),
  displacement: yup
    .number()
    .nullable()
    .transform((value: number) => (isNaN(value) ? null : value)),
  vehicleWidth: yup
    .number()
    .nullable()
    .transform((value: number) => (isNaN(value) ? null : value)),
  vehicleHeight: yup
    .number()
    .nullable()
    .transform((value: number) => (isNaN(value) ? null : value)),
  vehicleWeight: yup
    .number()
    .nullable()
    .transform((value: number) => (isNaN(value) ? null : value)),
  leavingAt: yup.date().nullable(),
  vehicleModel: yup
    .string()
    .nullable()
    .transform((value) => (value ? String(value) : "")),
  classificationDivisionNumber: yup
    .string()
    .nullable()
    .transform((value) => (value ? String(value) : "")),
  batteryCapacity: yup
    .number()
    .nullable()
    .transform((value: number) => (isNaN(value) ? null : value)),
  idlingStop: yup
    .boolean()
    .nullable()
    .transform((value) => !!value),
  stopOvers: yup
    .array()
    .nullable()
    .of(
      yup.object({
        address: yup
          .string()
          .nullable()
          .transform((value) => (value ? String(value) : ""))
          .test({
            message: (value) => {
              const index = toNumber(
                value?.path?.match(/stopOvers\[(\d+)\]/)?.[1],
              );
              return `立ち寄り地${
                typeof index === "number" ? index + 1 : ""
              }には緯度・経度を入力してください`;
            },
            test: (value) =>
              !!value && value?.split(",")?.filter(Boolean)?.length === 2,
          }),
        stayingSeconds: yup.number().nullable(),
      }),
    ),
});

const dailyReportFormSchema = yup.object({
  gasolineUnitPrice: yup
    .number()
    .transform((value: number) => (isNaN(value) ? null : value))
    .required((value) => typeof value === "number"),
  rapidChargeUnitPrice: yup
    .number()
    .transform((value: number) => (isNaN(value) ? null : value))
    .required((value) => typeof value === "number"),
  basicChargeUnitPrice: yup
    .number()
    .transform((value: number) => (isNaN(value) ? null : value))
    .required((value) => typeof value === "number"),
  dailyReports: yup
    .array()
    .of(dailyReportSchema)
    .required((value) => Array.isArray(value) && value.length > 0),
});

export type DailyReportFormInput = yup.InferType<typeof dailyReportFormSchema>;

export const useDailyReportSimulation = () => {
  const importFileInputRef = useRef<HTMLInputElement>(null);

  const {
    control,
    formState: { errors },
    register,
    setValue,
    clearErrors,
    handleSubmit,
  } = useForm<DailyReportFormInput>({
    defaultValues: {
      gasolineUnitPrice: DefaultSimulationValues.gasolineUnitPrice,
      rapidChargeUnitPrice: DefaultSimulationValues.rapidChargeUnitPrice,
      basicChargeUnitPrice: DefaultSimulationValues.basicChargeUnitPrice,
      dailyReports: [InitialDailyReport],
    },
    resolver: yupResolver(dailyReportFormSchema),
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const { fields } = useFieldArray({
    control,
    name: "dailyReports",
  });

  /**
   * 日報情報
   */
  const [
    gasolineUnitPrice,
    rapidChargeUnitPrice,
    basicChargeUnitPrice,
    dailyReports,
  ] = useWatch({
    control,
    name: [
      "gasolineUnitPrice",
      "rapidChargeUnitPrice",
      "basicChargeUnitPrice",
      "dailyReports",
    ],
  });

  /**
   * シミュレート可能な状態か
   */
  const canSimulate = useMemo(
    () =>
      gasolineUnitPrice &&
      rapidChargeUnitPrice &&
      basicChargeUnitPrice &&
      dailyReports.every(
        (dailyReport) =>
          dailyReport.vehicleId &&
          dailyReport.leavingFrom &&
          dailyReport.arrivingTo &&
          dailyReport.displacement &&
          dailyReport.vehicleWidth &&
          dailyReport.vehicleHeight &&
          dailyReport.vehicleWeight &&
          dailyReport.leavingAt,
      ),
    [
      gasolineUnitPrice,
      rapidChargeUnitPrice,
      basicChargeUnitPrice,
      dailyReports,
      errors,
    ],
  );

  /**
   * CSVファイルのインポート
   */
  const importCsvFile = () => {
    if (!importFileInputRef.current) return;

    importFileInputRef.current.value = "";

    // ファイル選択ダイアログを開く
    importFileInputRef.current.click();
  };

  /**
   * CSVデータを日報情報に変換する
   */
  const convertCsvRow = (row: DailyReportCsvRow) => {
    const newRow = [...row];
    const baseColumns = newRow.slice(0, BoundaryOfStopOverColumnIndex);
    const stopOverColumns = chunk(
      newRow.slice(BoundaryOfStopOverColumnIndex),
      3,
    ).filter((v) => v.some(Boolean));

    const vehicleId = toNumber(
      baseColumns[DailyReportColumnIndexes.vehicleId] as string,
    );

    const leavingFromLatitude = toNumber(
      baseColumns[DailyReportColumnIndexes.leavingFromLatitude] as string,
    );
    const leavingFromLongitude = toNumber(
      baseColumns[DailyReportColumnIndexes.leavingFromLongitude] as string,
    );
    const leavingFrom =
      leavingFromLatitude || leavingFromLongitude
        ? `${leavingFromLatitude || ""},${leavingFromLongitude || ""}`
        : null;
    const arrivingToLatitude = toNumber(
      baseColumns[DailyReportColumnIndexes.arrivingToLatitude] as string,
    );
    const arrivingToLongitude = toNumber(
      baseColumns[DailyReportColumnIndexes.arrivingToLongitude] as string,
    );
    const arrivingTo =
      arrivingToLatitude || arrivingToLongitude
        ? `${arrivingToLatitude || ""},${arrivingToLongitude || ""}`
        : null;
    const displacement = toNumber(
      baseColumns[DailyReportColumnIndexes.displacement] as string,
    );
    const vehicleWidth = toNumber(
      baseColumns[DailyReportColumnIndexes.vehicleWidth] as string,
    );
    const vehicleHeight = toNumber(
      baseColumns[DailyReportColumnIndexes.vehicleHeight] as string,
    );
    const vehicleWeight = toNumber(
      baseColumns[DailyReportColumnIndexes.vehicleWeight] as string,
    );
    const leavingAt = toDate(
      baseColumns[DailyReportColumnIndexes.leavingAt] as string,
    );
    const vehicleModel = baseColumns[
      DailyReportColumnIndexes.vehicleModel
    ] as string;
    const classificationDivisionNumber = baseColumns[
      DailyReportColumnIndexes.classificationDivisionNumber
    ] as string;
    const batteryCapacity = toNumber(
      baseColumns[DailyReportColumnIndexes.batteryCapacity] as string,
    );
    const idlingStop = toBoolean(
      baseColumns[DailyReportColumnIndexes.idlingStop] as string,
    );
    const stopOvers = stopOverColumns.map((stopOverColumn) => {
      const leavingFromLatitude = toNumber(
        stopOverColumn[
          DailyReportColumnIndexes.stopOver.stopOverLatitude
        ] as string,
      );
      const leavingFromLongitude = toNumber(
        stopOverColumn[
          DailyReportColumnIndexes.stopOver.stopOverLongitude
        ] as string,
      );
      const stayingTime = stopOverColumn[
        DailyReportColumnIndexes.stopOver.stopOverStayingTime
      ] as string;
      const [hours, minutes, seconds] = stayingTime.split(":");

      return {
        address:
          leavingFromLatitude || leavingFromLongitude
            ? `${leavingFromLatitude || ""},${leavingFromLongitude || ""}`
            : null,
        stayingSeconds: toSeconds(hours, minutes, seconds),
      };
    });

    return {
      vehicleId,
      leavingFrom,
      arrivingTo,
      displacement,
      vehicleWidth,
      vehicleHeight,
      vehicleWeight,
      leavingAt,
      vehicleModel,
      classificationDivisionNumber,
      batteryCapacity,
      idlingStop,
      stopOvers,
    };
  };

  /**
   * CSVファイルを読み込む
   */
  const loadCsvFile = (ce: ChangeEvent<HTMLInputElement>) => {
    if (!ce?.target?.files) return;

    clearErrors();

    const file = ce.target.files[0];
    const reader = new FileReader();

    reader.onload = (pe) => {
      if (!pe?.target?.result) return;

      const codes = new Uint8Array(pe.target.result as ArrayBuffer);
      const encoding = detect(codes);

      // 対応していない文字コードであれば以降の処理を行わない
      if (!encoding) return;

      // 文字化け回避のための変換を行う
      const unicodeString = convert(codes, {
        to: "UNICODE",
        from: encoding,
        type: "string",
      });

      parse<DailyReportCsvRow>(unicodeString, {
        skipEmptyLines: true,
        transform: (value) => (value ? toHalfWidth(value) : value),
        complete: (results) => {
          if (!results?.data) return;

          const rows = results.data.slice(1);
          setValue(
            "dailyReports",
            rows.map(
              (row) =>
                convertCsvRow(
                  row,
                ) as DailyReportFormInput["dailyReports"][number],
            ),
          );
        },
      });
    };

    if (!file) return;

    reader.readAsArrayBuffer(file);
  };

  /**
   * シミュレーション結果画面へ遷移
   */
  const simulation = handleSubmit((data) => {
    openWindowWithSession(Paths.RESULT, data, Species.DAYLY_REPORT);
  });

  return {
    control,
    fields,
    errors,
    fileRef: importFileInputRef,
    canSimulate,
    register,
    importCsvFile: useCallback(importCsvFile, []),
    loadCsvFile: useCallback(loadCsvFile, []),
    simulation: useCallback(simulation, []),
  };
};

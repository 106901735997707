import { ReactNode } from "react";
import { useLocation } from "react-router-dom";

import { CustomFlowbiteTheme, Flowbite } from "flowbite-react";

import { Header, SideMenu } from "@/components/layouts";
import { IsNotAppllyLayoutPath } from "@/constants";
import { useAuth } from "@/hooks";

const customTheme: CustomFlowbiteTheme = {
  tabs: {
    tablist: {
      tabitem: {
        base: "flex items-center justify-center pb-4 rounded-t-lg text-sm font-medium first:ml-0 text-primary-main px-6",
        styles: {
          underline: {
            active: {
              on: "border-b-2 border-primary-main font-bold",
              off: "text-primary-deep",
            },
          },
        },
      },
    },
  },
};

export const Layout = ({ children }: { children: ReactNode }) => {
  const { company } = useAuth();
  const location = useLocation();
  const isNotAppllyLayoutPathPage = IsNotAppllyLayoutPath.some(
    (path) => location.pathname === path,
  );

  return (
    <div className='font-sans text-primary-deep'>
      {company ? (
        <Flowbite theme={{ theme: customTheme }}>
          <Header />
          <div className='flex w-[95%]'>
            {!isNotAppllyLayoutPathPage && <SideMenu />}
            <div
              className='border-1 border-l'
              style={{ height: "calc(100vh - 70px)" }}
            ></div>
            <div className='w-full p-11'>{children}</div>
          </div>
        </Flowbite>
      ) : (
        children
      )}
    </div>
  );
};

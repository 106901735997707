export const Paths = {
  HOME: "/simulation/effectiveness",
  RESULT: "/simulation/effectiveness/result",
  LOGIN: "/login",
} as const;

export const ExternalPaths = {
  GEOCODE: "/api/geocode/json",
} as const;

export const IsNotAppllyLayoutPath = [Paths.RESULT];

import dayjs, { extend } from "dayjs";
import duration from "dayjs/plugin/duration";

extend(duration);

/**
 * 全角→半角に変換する
 */
export const toHalfWidth = (text: string): string => {
  text = text.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });

  return text;
};

/**
 * 文字列を数値に変換する
 * NaNと判定される場合はnullが返される
 */
export const toNumber = (text: string): number | null => {
  if (text === "") return null;

  const number = Number(text);
  return isNaN(number) ? null : number;
};

/**
 * 文字列を真偽値に変換する
 */
export const toBoolean = (text: string): boolean | null => {
  let from = text;

  if (typeof text != "string") {
    from = String(text);
  }

  from = from.toLowerCase();

  try {
    const to = JSON.parse(from);
    return to === true;
  } catch (e) {
    return from.includes("true")
      ? true
      : from.includes("false")
      ? false
      : !!from;
  }
};

/**
 * 文字列を日付に変換する
 * 日付と判定されない場合はnullが返される
 */
export const toDate = (text: string): Date | null =>
  dayjs(text).isValid() ? dayjs(text).toDate() : null;

/**
 * 配列を任意のサイズに分割する
 */
export const chunk = (list: unknown[], size: number) =>
  Array.from({ length: Math.ceil(list.length / size) }, (_, i) =>
    list.slice(i * size, i * size + size),
  );

/**
 * 時分秒を秒に変換する
 */
export const toSeconds = (hours: string, minutes: string, seconds: string) => {
  return dayjs
    .duration({
      hours: Number(hours),
      minutes: Number(minutes),
      seconds: Number(seconds),
    })
    .asSeconds();
};

import { useCallback, useEffect, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";

import { DefaultSimulationValues, InitialTrip } from "@/components/simulation";
import { Paths, Species } from "@/constants";
import { useToast, useAuth } from "@/hooks";
import { Option } from "@/types";
import { apiGet } from "@/utils/api";
import { openWindowWithSession } from "@/utils/window";

const TripFormSchema = yup.object({
  gasolineUnitPrice: yup
    .number()
    .transform((value: number) => (isNaN(value) ? null : value))
    .required((value) => typeof value === "number"),
  rapidChargeUnitPrice: yup
    .number()
    .transform((value: number) => (isNaN(value) ? null : value))
    .required((value) => typeof value === "number"),
  basicChargeUnitPrice: yup
    .number()
    .transform((value: number) => (isNaN(value) ? null : value))
    .required((value) => typeof value === "number"),
  trips: yup
    .array()
    .min(1, "トライブーを選択してください")
    .required((value) => Array.isArray(value) && value.length > 0)
    .of(
      yup.object({
        driverId: yup
          .number()
          .transform((value: number) =>
            isNaN(value) || value <= 0 ? null : value,
          )
          .required((value) => typeof value === "number" && value > 0),
        timestampMin: yup
          .date()
          .max(yup.ref("timestampMax"), "終了日時以前を入力してください")
          .test({
            message: "過去の日時を入力してください",
            test: (value) => !!value && dayjs(value).isBefore(dayjs()),
          }),
        timestampMax: yup
          .date()
          .min(yup.ref("timestampMin"), "開始日時以降を入力してください")
          .test({
            message: "過去の日時を入力してください",
            test: (value) => !!value && dayjs(value).isBefore(dayjs()),
          }),
      }),
    ),
});

export type TripFormInput = yup.InferType<typeof TripFormSchema>;

export const useTripSimulation = () => {
  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<TripFormInput>({
    defaultValues: {
      gasolineUnitPrice: DefaultSimulationValues.gasolineUnitPrice,
      rapidChargeUnitPrice: DefaultSimulationValues.rapidChargeUnitPrice,
      basicChargeUnitPrice: DefaultSimulationValues.basicChargeUnitPrice,
      trips: [InitialTrip],
    },
    resolver: yupResolver(TripFormSchema),
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "trips",
  });
  const { error } = useToast();
  const { company } = useAuth();

  const [drivers, setIsDrivers] = useState<Option[] | null>(null);

  /**
   * トリップ情報
   */
  const [gasolineUnitPrice, rapidChargeUnitPrice, basicChargeUnitPrice, trips] =
    useWatch({
      control,
      name: [
        "gasolineUnitPrice",
        "rapidChargeUnitPrice",
        "basicChargeUnitPrice",
        "trips",
      ],
    });

  /**
   * シミュレート可能な状態か
   */
  const canSimulate = useMemo(
    () =>
      gasolineUnitPrice &&
      rapidChargeUnitPrice &&
      basicChargeUnitPrice &&
      trips.every(
        (trip) => trip.driverId && trip.timestampMin && trip.timestampMax,
      ),
    [
      gasolineUnitPrice,
      rapidChargeUnitPrice,
      basicChargeUnitPrice,
      trips.map((trip) => trip.driverId),
    ],
  );

  /**
   * トリップ情報を追加する
   */
  const addTrip = () => append(InitialTrip);

  /**
   * トリップ情報を削除する
   */
  const removeTrip = (index: number) => remove(index);

  /**
   * ドライバー情報を取得する
   */
  const getDrivers = async () => {
    const errMsg = "ドライバー情報の取得に失敗しました";

    const res = await apiGet<Option[]>(`/api/drivers`);

    if (res.code !== 200 && company) return error(errMsg);
    setIsDrivers(res.data);
  };

  /**
   * シミュレーション結果画面へ遷移
   */
  const simulation = handleSubmit((data) => {
    openWindowWithSession(Paths.RESULT, data, Species.TRIP);
  });

  useEffect(() => {
    getDrivers();
  }, []);

  return {
    drivers,
    control,
    fields,
    errors,
    canSimulate,
    register,
    addTrip: useCallback(addTrip, []),
    removeTrip: useCallback(removeTrip, []),
    simulation: useCallback(simulation, []),
  };
};

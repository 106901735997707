/**
 * 削減効果シミュレーションのデフォルト値
 */
export const DefaultSimulationValues = {
  /** ガソリン単価(円) */
  gasolineUnitPrice: 190,
  /** 急速充電時単価(円/分) */
  rapidChargeUnitPrice: 16.5,
  /** 基礎充電時単価(円/kWh) */
  basicChargeUnitPrice: 30,
} as const;

/**
 * トリップ情報の最大値
 */
export const MaxTripCount = 3;

/**
 * トリップ情報の初期値
 */
export const InitialTrip = {
  driverId: 0,
  timestampMin: undefined,
  timestampMax: undefined,
} as const;

/**
 * 日報情報CSVデータの列番号
 */
export const DailyReportColumnIndexes = {
  vehicleId: 0, // 車両ID
  leavingAt: 1, // 出発日時
  leavingFromLatitude: 2, // 出発地緯度
  leavingFromLongitude: 3, // 出発地経度
  arrivingToLatitude: 4, // 目的地緯度
  arrivingToLongitude: 5, // 目的地経度
  vehicleModel: 6, // 車両形式
  classificationDivisionNumber: 7, // 類別区分番号
  idlingStop: 8, // アイドリングストップの有無
  displacement: 9, // 排気量
  batteryCapacity: 10, // 電池容量
  vehicleWidth: 11, // 車幅
  vehicleHeight: 12, // 車高
  vehicleWeight: 13, // 車重
  // 以下立ち寄り地情報は複数存在する場合もある
  stopOver: {
    stopOverLatitude: 0, // 立ち寄り地緯度
    stopOverLongitude: 1, // 立ち寄り地経度
    stopOverStayingTime: 2, // 立ち寄り地滞在時間（hh:mm:ss形式）
  },
} as const;

/**
 * 立ち寄り地情報が格納されている列が始まるインデックス
 */
export const BoundaryOfStopOverColumnIndex = 14;

/**
 * 日報情報の初期値
 */
export const InitialDailyReport = {
  vehicleId: undefined,
  leavingFrom: "",
  arrivingTo: "",
  displacement: undefined,
  vehicleWidth: undefined,
  vehicleHeight: undefined,
  vehicleWeight: undefined,
  leavingAt: undefined,
  vehicleModel: "",
  classificationDivisionNumber: "",
  batteryCapacity: undefined,
  idlingStop: true,
  stopOvers: [
    {
      address: "",
      stayingSeconds: undefined,
    },
  ] as Record<string, string | number | undefined>[],
} as const;

/**
 * ルート情報の初期値
 */
export const InitialRoute = {
  displacement: undefined,
  vehicleWidth: undefined,
  vehicleHeight: undefined,
  vehicleWeight: undefined,
  leavingAt: new Date(),
  vehicleModel: "",
  classificationDivisionNumber: "",
  batteryCapacity: undefined,
  idlingStop: true,
  leavingFromLatitude: undefined,
  leavingFromLongitude: undefined,
  arrivingToLatitude: undefined,
  arrivingToLongitude: undefined,
} as const;

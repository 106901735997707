import Modal, { Styles } from "react-modal";

import { Button, Input } from "@/components/parts";
import { useLoginPage } from "@/pages/hooks";

const modalStyle: Styles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "fit-content",
    width: "800px",
    maxWidth: "100%",
    background: "#fff",
    borderRadius: "10px",
    padding: "50px",
    overflow: "hidden",
  },
};

export function Login() {
  const { isSubmitting, errors, register, login } = useLoginPage();

  return (
    <Modal isOpen={true} style={modalStyle} ariaHideApp={false}>
      <div className='flex flex-col justify-center gap-y-[50px]'>
        <div className='flex w-full justify-center'>
          <img
            src='/images/corporate_symbol.jpg'
            alt='symbol'
            className='h-fit w-[200px]'
          ></img>
        </div>
        <h1 className='text-center text-[30px] font-[400]'>ログイン</h1>
        <form className='flex flex-col items-center justify-center gap-y-[50px]'>
          <div className='flex flex-col gap-y-[10px]'>
            <div className='flex flex-col gap-y-2'>
              <label
                className='flex flex-row items-center gap-x-[10px]'
                htmlFor='id'
              >
                <span className='w-[87px] flex-shrink-0 text-[14px] font-[400]'>
                  ID
                </span>
                <Input
                  id='id'
                  type='text'
                  disabled={isSubmitting}
                  {...register("code")}
                />
              </label>
              {errors.code && (
                <p className='ml-[calc(87px+10px)] text-[12px] text-error'>
                  {errors.code.message}
                </p>
              )}
            </div>
            <div className='flex flex-col gap-y-2'>
              <label
                className='flex flex-row items-center gap-x-[10px]'
                htmlFor='password'
              >
                <span className='w-[87px] flex-shrink-0 text-[14px] font-[400]'>
                  パスワード
                </span>
                <Input
                  id='password'
                  type='password'
                  disabled={isSubmitting}
                  {...register("password")}
                />
              </label>
              {errors.password && (
                <p className='ml-[calc(87px+10px)] text-[12px] text-error'>
                  {errors.password.message}
                </p>
              )}
            </div>
            {errors.root?.message && (
              <p className='ml-[calc(87px+10px)] text-[12px] text-error'>
                {errors.root.message}
              </p>
            )}
          </div>
          <Button
            type='button'
            className='w-[250px] font-[700]'
            disabled={isSubmitting}
            onClick={login}
          >
            ログインする
          </Button>
        </form>
      </div>
    </Modal>
  );
}

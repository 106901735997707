import { Fragment } from "react";

import dayjs from "dayjs";
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldError,
  FieldErrors,
  Merge,
} from "react-hook-form";

import { Input, TimePicker } from "@/components/parts";
import { DailyReportFormInput } from "@/components/simulation";

type Props = {
  index: number;
  control: Control<DailyReportFormInput>;
  field: FieldArrayWithId<DailyReportFormInput, "dailyReports", "id">;
  errors: FieldErrors<DailyReportFormInput>;
};

export const DailyReportRow = ({ index, control, field, errors }: Props) => {
  const reportErrors = errors?.dailyReports?.[index] ?? null;
  const stopOverErrors = (reportErrors?.stopOvers ?? []) as Merge<
    FieldError,
    Record<string, Record<string, string>>
  >[];
  const hasErrors = Object.keys(reportErrors ?? {}).length > 0;

  return (
    <div className='flex w-full flex-col gap-y-4 rounded-md border-2 border-dashed p-6 pt-4'>
      <div className='grid w-full grid-cols-4 items-end gap-x-4'>
        <Controller
          control={control}
          name={`dailyReports.${index}.vehicleId`}
          render={({ field: { onChange, value } }) => (
            <Input
              disabled={true}
              id={`vehicleId-${index}`}
              type='text'
              className='w-full bg-black-opacity'
              placeholder='001'
              onChange={onChange}
              value={value || ""}
            >
              車両id*
            </Input>
          )}
        />
        <Controller
          control={control}
          name={`dailyReports.${index}.leavingFrom`}
          render={({ field: { onChange, value } }) => (
            <Input
              disabled={true}
              id={`leaving-from-${index}`}
              type='text'
              className='w-full bg-black-opacity'
              placeholder='緯度・経度、または住所'
              onChange={onChange}
              value={value || ""}
            >
              出発地*
            </Input>
          )}
        />
        <Controller
          control={control}
          name={`dailyReports.${index}.arrivingTo`}
          render={({ field: { onChange, value } }) => (
            <Input
              disabled={true}
              id={`arriving-to-${index}`}
              type='text'
              className='w-full bg-black-opacity'
              placeholder='緯度・経度、または住所'
              onChange={onChange}
              value={value || ""}
            >
              目的地*
            </Input>
          )}
        />
        <Controller
          control={control}
          name={`dailyReports.${index}.leavingAt`}
          render={({ field: { onChange, value } }) => (
            <label htmlFor={`leaving-at-${index}`} className='flex flex-col'>
              <span className='mb-2 text-[14px] font-medium text-primary-deep'>
                出発日時*
              </span>
              <TimePicker
                disabled={true}
                defaultValue={
                  value && dayjs(value).isValid() ? value : undefined
                }
                wrapperProps={{ className: "h-8" }}
                wrapperClassName='w-full'
                setTimeStamp={onChange}
                className='bg-black-opacity'
              />
            </label>
          )}
        />
      </div>
      <div className='grid w-full grid-cols-4 items-end gap-x-4'>
        <Controller
          control={control}
          name={`dailyReports.${index}.displacement`}
          render={({ field: { onChange, value } }) => (
            <Input
              disabled={true}
              id={`displacement-${index}`}
              type='number'
              className='w-full bg-black-opacity'
              placeholder='1.49'
              onChange={onChange}
              value={value || ""}
            >
              排気量[L]*
            </Input>
          )}
        />
        <Controller
          control={control}
          name={`dailyReports.${index}.vehicleWidth`}
          render={({ field: { onChange, value } }) => (
            <Input
              disabled={true}
              id={`vehicle-width-${index}`}
              type='number'
              className='w-full bg-black-opacity'
              placeholder='174.5'
              onChange={onChange}
              value={value || ""}
            >
              車幅[cm]*
            </Input>
          )}
        />
        <Controller
          control={control}
          name={`dailyReports.${index}.vehicleHeight`}
          render={({ field: { onChange, value } }) => (
            <Input
              disabled={true}
              id={`vehicle-height-${index}`}
              type='number'
              className='w-full bg-black-opacity'
              placeholder='143.5'
              onChange={onChange}
              value={value || ""}
            >
              車高[cm]*
            </Input>
          )}
        />
        <Controller
          control={control}
          name={`dailyReports.${index}.vehicleWeight`}
          render={({ field: { onChange, value } }) => (
            <Input
              disabled={true}
              id={`vehicle-weight-${index}`}
              type='number'
              className='w-full bg-black-opacity'
              placeholder='1230'
              onChange={onChange}
              value={value || ""}
            >
              車重[kg]*
            </Input>
          )}
        />
      </div>
      <div className='mt-8 grid w-full grid-cols-4 items-end  gap-x-4'>
        <Controller
          control={control}
          name={`dailyReports.${index}.vehicleModel`}
          render={({ field: { onChange, value } }) => (
            <Input
              disabled={true}
              id={`vehicle-model-${index}`}
              type='text'
              className='w-full bg-black-opacity'
              placeholder='5BA-MXEA17'
              onChange={onChange}
              value={value || ""}
            >
              車両形式
            </Input>
          )}
        />
        <Controller
          control={control}
          name={`dailyReports.${index}.classificationDivisionNumber`}
          render={({ field: { onChange, value } }) => (
            <Input
              disabled={true}
              id={`classification-division-number-${index}`}
              type='text'
              className='w-full bg-black-opacity'
              placeholder='0001'
              onChange={onChange}
              value={value || ""}
            >
              類別区分番号
            </Input>
          )}
        />
        <Controller
          control={control}
          name={`dailyReports.${index}.batteryCapacity`}
          render={({ field: { onChange, value } }) => (
            <Input
              disabled={true}
              id={`battery-capacity-${index}`}
              type='number'
              className='w-full bg-black-opacity'
              placeholder='40'
              onChange={onChange}
              value={value || ""}
            >
              電池容量[kWh]
            </Input>
          )}
        />
        <Controller
          control={control}
          name={`dailyReports.${index}.idlingStop`}
          render={({ field: { onChange, value } }) => (
            <div className='col-span-1 flex w-full flex-col'>
              <span className='mb-2 text-[14px] font-medium text-primary-deep'>
                アイドリングストップの有無
              </span>
              <div className='flex h-full flex-row items-center gap-x-10'>
                <label
                  htmlFor={`idling-stop-yes-${index}`}
                  className='w-fix flex h-full items-center gap-x-2'
                >
                  <Input
                    disabled={true}
                    className='w-fit rounded-full bg-black-opacity px-[7px]'
                    type='radio'
                    id={`idling-stop-yes-${index}`}
                    name={`idling-stop-${index}`}
                    checked={!!value}
                    onChange={() => onChange(true)}
                  />
                  <span className='text-[14px] font-medium text-primary-deep'>
                    有
                  </span>
                </label>
                <label
                  htmlFor={`idling-stop-no-${index}`}
                  className='w-fix flex h-full items-center gap-x-2'
                >
                  <Input
                    disabled={true}
                    className='w-fit rounded-full bg-black-opacity px-[7px]'
                    type='radio'
                    id={`idling-stop-no-${index}`}
                    name={`idling-stop-${index}`}
                    checked={!value}
                    onChange={() => onChange(false)}
                  />
                  <span className='text-[14px] font-medium text-primary-deep'>
                    無
                  </span>
                </label>
              </div>
            </div>
          )}
        />
      </div>
      <div className='grid w-full grid-cols-4 items-end gap-4'>
        {field.stopOvers?.map((_, stopOverIndex) => (
          <Fragment key={stopOverIndex}>
            <div className='col-span-2 w-full'>
              <Controller
                control={control}
                name={`dailyReports.${index}.stopOvers.${stopOverIndex}.address`}
                render={({ field: { onChange, value } }) => (
                  <Input
                    disabled={true}
                    id={`stop-over-location-${index}`}
                    type='text'
                    className='w-full bg-black-opacity'
                    placeholder='緯度・経度、または住所'
                    value={value || ""}
                    onChange={onChange}
                  >
                    立ち寄り地{stopOverIndex + 1}
                  </Input>
                )}
              />
            </div>
            <Controller
              control={control}
              name={`dailyReports.${index}.stopOvers.${stopOverIndex}.stayingSeconds`}
              render={({ field: { onChange, value } }) => (
                <Input
                  disabled={true}
                  id={`stop-over-time-${index}`}
                  type='number'
                  className='w-full bg-black-opacity'
                  placeholder='100'
                  value={value || ""}
                  onChange={onChange}
                >
                  立ち寄り地の滞在時間[秒]
                </Input>
              )}
            />
          </Fragment>
        ))}
      </div>
      {hasErrors && (
        <div className='mt-2 flex flex-col'>
          {reportErrors?.leavingFrom && (
            <p className='text-[14px] text-error'>
              {reportErrors.leavingFrom.message}
            </p>
          )}
          {reportErrors?.arrivingTo && (
            <p className='text-[14px] text-error'>
              {reportErrors.arrivingTo.message}
            </p>
          )}
          {reportErrors?.displacement && (
            <p className='text-[14px] text-error'>
              {reportErrors.displacement.message}
            </p>
          )}
          {reportErrors?.vehicleWidth && (
            <p className='text-[14px] text-error'>
              {reportErrors.vehicleWidth.message}
            </p>
          )}
          {reportErrors?.vehicleHeight && (
            <p className='text-[14px] text-error'>
              {reportErrors.vehicleHeight.message}
            </p>
          )}
          {reportErrors?.vehicleWeight && (
            <p className='text-[14px] text-error'>
              {reportErrors.vehicleWeight.message}
            </p>
          )}
          {reportErrors?.leavingAt && (
            <p className='text-[14px] text-error'>
              {reportErrors.leavingAt.message}
            </p>
          )}
          {stopOverErrors &&
            stopOverErrors?.map((_, stopOverIndex) => (
              <Fragment key={stopOverIndex}>
                {stopOverErrors?.[stopOverIndex]?.address && (
                  <p className='text-[14px] text-error'>
                    {stopOverErrors?.[stopOverIndex]?.address?.message}
                  </p>
                )}
              </Fragment>
            ))}
        </div>
      )}
    </div>
  );
};

import { useState, useEffect } from "react";

import { Sidebar } from "flowbite-react";
import { VscGraph } from "react-icons/vsc";

export const SideMenu = () => {
  const [selectedItem, setSelectedItem] = useState<string>("");
  const [isCollapseOpen] = useState<boolean>(true); // 常にCollapseを開いておく

  useEffect(() => {
    const pathname = window.location.pathname;
    const lastSegment = pathname.split("/").pop() as string;
    setSelectedItem(lastSegment);
  }, []);

  return (
    <Sidebar
      style={{ backgroundColor: "white", position: "relative", top: 10 }}
    >
      <Sidebar.Items
        style={{ backgroundColor: "white", margin: "-16px", padding: "25px" }}
      >
        <Sidebar.ItemGroup>
          <Sidebar.Collapse
            icon={VscGraph}
            label='シミュレーション'
            open={isCollapseOpen}
            style={{ fontSize: "14px", color: "#172B4D", width: "214px" }}
          >
            <Sidebar.Item
              href='/simulation/effectiveness'
              onClick={() => setSelectedItem("effectiveness")}
              style={{
                backgroundColor:
                  selectedItem === "effectiveness" ? "#E9F2FF" : "transparent",
                fontWeight:
                  selectedItem === "effectiveness" ? "bold" : "normal",
                color: selectedItem === "effectiveness" ? "#0769B0" : "#172B4D",
                textAlign: "left",
                fontSize: "14px",
              }}
            >
              削減効果
            </Sidebar.Item>
            <Sidebar.Item
              href='/simulation/possibility'
              onClick={() => setSelectedItem("possibility")}
              style={{
                backgroundColor:
                  selectedItem === "possibility" ? "#E9F2FF" : "transparent",
                fontWeight: selectedItem === "possibility" ? "bold" : "normal",
                color: selectedItem === "possibility" ? "#0769B0" : "#172B4D",
                textAlign: "left",
                fontSize: "14px",
              }}
            >
              運行可能性評価
            </Sidebar.Item>
            <Sidebar.Item
              href='/simulation/charging-analysis'
              onClick={() => setSelectedItem("charge-analysis")}
              style={{
                backgroundColor:
                  selectedItem === "charging-analysis"
                    ? "#E9F2FF"
                    : "transparent",
                fontWeight:
                  selectedItem === "charging-analysis" ? "bold" : "normal",
                color:
                  selectedItem === "charging-analysis" ? "#0769B0" : "#172B4D",
                textAlign: "left",
                fontSize: "14px",
              }}
            >
              基礎充電・経路充電分析
            </Sidebar.Item>
            <Sidebar.Item
              href='/simulation/charging-amount'
              onClick={() => setSelectedItem("charge-amount")}
              style={{
                backgroundColor:
                  selectedItem === "charging-amount"
                    ? "#E9F2FF"
                    : "transparent",
                textAlign: "left",
                fontSize: "14px",
                fontWeight:
                  selectedItem === "charging-amount" ? "bold" : "normal",
                color:
                  selectedItem === "charging-amount" ? "#0769B0" : "#172B4D",
              }}
            >
              充電電力量
            </Sidebar.Item>
          </Sidebar.Collapse>
        </Sidebar.ItemGroup>
      </Sidebar.Items>
    </Sidebar>
  );
};

import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

import { Param } from "@/types/selector";

const { persistAtom } = recoilPersist({
  key: "recoil-persist",
  storage: sessionStorage,
});

export const paramState = atom<Param | null>({
  key: "param",
  default: null,
  effects_UNSTABLE: [persistAtom],
});

import { Param } from "@/types/selector";

type Props = {
  params: Param | null;
};

export const SupplementaryText = ({ params }: Props) => {
  return (
    <div className='rounded-md border p-4 text-[12px] text-primary-deep'>
      ※1 ガソリン代は¥{params?.gasolineUnitPrice}/Lで計算 <br />
      ※2 電気代は¥{params?.basicChargeUnitPrice}/kWhで計算 <br />
      ※3 経路充電は電池容量{"-"}Whの車両が残電量20％({"-"}
      kWh)ごとに充電を開始し、80%(={"-"}kWh)まで充電を行うものとします
      <br />
      ※4 経路充電における電気代は、下記の式に基づき計算
      <br />
      &emsp;急速：¥4,180/月＋￥{params?.rapidChargeUnitPrice}/分 <br />
      ※5 ガソリンのCO2排出係数を2.322[kg-CO2/L]で計算 <br />
      ※6 電気の基礎排出係数を0.000457[t-CO2/kWh]で計算 <br />
      ※7 ルート探索に基づくシュミレーション結果：（日報検索、ルート検索の場合） <br />
      ※8 シュミレーション対象外のレコードは例外として除外：（ID検索、ルート検索の場合）
    </div>
  );
};

export default SupplementaryText;

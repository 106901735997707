import { ButtonHTMLAttributes } from "react";

import { IconType } from "react-icons";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  Icon?: IconType;
};

export const Button = ({
  type,
  disabled = false,
  className = "",
  Icon,
  children,
  ...rest
}: ButtonProps) => {
  return (
    <button
      type={type}
      className={`flex justify-center rounded-md px-8 py-2.5 text-[16px] text-white  ${className} ${
        disabled ? "bg-black-light" : "bg-primary-dark hover:bg-primary-main"
      }`}
      disabled={disabled}
      {...rest}
    >
      {Icon && <Icon className='mr-3 mt-[2px] h-5 w-5' />}
      {children}
    </button>
  );
};

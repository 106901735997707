import { Control, Controller, FieldErrors } from "react-hook-form";

import { Input } from "@/components/parts";
import { RouteFormInput } from "@/components/simulation";

type Props = {
  index: number;
  errors: FieldErrors<RouteFormInput>;
  control: Control<RouteFormInput>;
};

export const RouteRow = ({ index, control }: Props) => {
  return (
    <div className='flex w-full flex-col gap-y-4 rounded-md border-2 border-dashed p-6 pt-4'>
      <div className='grid w-full grid-cols-2 items-end gap-x-4'>
        <Controller
          control={control}
          name={`routes.${index}.displacement`}
          render={({ field: { onChange, value } }) => (
            <Input
              id={`displacement-${index}`}
              type='number'
              className='w-full'
              placeholder='1.49'
              onChange={onChange}
              value={value || ""}
            >
              排気量[L]*
            </Input>
          )}
        />
        <Controller
          control={control}
          name={`routes.${index}.vehicleWidth`}
          render={({ field: { onChange, value } }) => (
            <Input
              id={`vehicle-width-${index}`}
              type='number'
              className='w-full'
              placeholder='174.5'
              onChange={onChange}
              value={value || ""}
            >
              車幅[cm]*
            </Input>
          )}
        />
      </div>
      <div className='grid w-full grid-cols-2 items-end gap-x-4'>
        <Controller
          control={control}
          name={`routes.${index}.vehicleHeight`}
          render={({ field: { onChange, value } }) => (
            <Input
              id={`vehicle-height-${index}`}
              type='number'
              className='w-full'
              placeholder='143.5'
              onChange={onChange}
              value={value || ""}
            >
              車高[cm]*
            </Input>
          )}
        />
        <Controller
          control={control}
          name={`routes.${index}.vehicleWeight`}
          render={({ field: { onChange, value } }) => (
            <Input
              id={`vehicle-weight-${index}`}
              type='number'
              className='w-full'
              placeholder='1230'
              onChange={onChange}
              value={value || ""}
            >
              車重[kg]*
            </Input>
          )}
        />
      </div>
      <div className='mt-8 grid w-full grid-cols-2 items-end gap-x-4'>
        <Controller
          control={control}
          name={`routes.${index}.vehicleModel`}
          render={({ field: { onChange, value } }) => (
            <Input
              id={`vehicle-model-${index}`}
              type='text'
              className='w-full'
              placeholder='5BA-MXEA17'
              onChange={onChange}
              value={value || ""}
            >
              車両形式
            </Input>
          )}
        />
        <Controller
          control={control}
          name={`routes.${index}.classificationDivisionNumber`}
          render={({ field: { onChange, value } }) => (
            <Input
              id={`classification-division-number-${index}`}
              type='text'
              className='w-full'
              placeholder='0001'
              onChange={onChange}
              value={value || ""}
            >
              類別区分番号
            </Input>
          )}
        />
      </div>
      <div className='grid w-full grid-cols-2 items-end gap-x-4'>
        <Controller
          control={control}
          name={`routes.${index}.batteryCapacity`}
          render={({ field: { onChange, value } }) => (
            <Input
              id={`battery-capacity-${index}`}
              type='number'
              className='w-full'
              placeholder='40'
              onChange={onChange}
              value={value || ""}
            >
              電池容量[kWh]
            </Input>
          )}
        />
        <Controller
          control={control}
          name={`routes.${index}.idlingStop`}
          render={({ field: { onChange, value } }) => (
            <div className='flex h-full w-full flex-col'>
              <span className='mb-2 text-[14px] font-medium text-primary-deep'>
                アイドリングストップの有無
              </span>
              <div className='flex h-full flex-row items-center gap-x-10'>
                <label
                  htmlFor={`idling-stop-yes-${index}`}
                  className='w-fix flex h-full items-center gap-x-2'
                >
                  <Input
                    className='w-fit rounded-full px-[7px]'
                    type='radio'
                    id={`idling-stop-yes-${index}`}
                    name={`idling-stop-${index}`}
                    checked={!!value}
                    onChange={() => onChange(true)}
                  />
                  <span className='text-[14px] font-medium text-primary-deep'>
                    有
                  </span>
                </label>
                <label
                  htmlFor={`idling-stop-no-${index}`}
                  className='w-fix flex h-full items-center gap-x-2'
                >
                  <Input
                    className='w-fit rounded-full px-[7px]'
                    type='radio'
                    id={`idling-stop-no-${index}`}
                    name={`idling-stop-${index}`}
                    checked={!value}
                    onChange={() => onChange(false)}
                  />
                  <span className='text-[14px] font-medium text-primary-deep'>
                    無
                  </span>
                </label>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

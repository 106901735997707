import { FieldErrors, Path, UseFormRegister } from "react-hook-form";

import { Input } from "@/components/parts";
import { UnitPrice } from "@/components/simulation";

interface Props<T extends UnitPrice = UnitPrice> {
  register: UseFormRegister<T>;
  errors: FieldErrors<UnitPrice>;
}

export const UnitPriceInput = <T extends UnitPrice = UnitPrice>(
  props: Props<T>,
) => {
  return (
    <div className='flex w-full pb-10'>
      <div className='mr-4 w-1/3'>
        <p className='mb-2 text-[14px] font-medium text-primary-deep'>
          ガソリン単価[円]*
        </p>
        <Input
          id='gasolineUnitPrice'
          type='number'
          {...props.register("gasolineUnitPrice" as Path<T>)}
          step='0.01'
          className='w-full'
        />
        {props.errors.gasolineUnitPrice && (
          <p className='absolute text-[12px] text-error'>
            {props.errors.gasolineUnitPrice.message}
          </p>
        )}
      </div>
      <div className='mr-4 w-1/3'>
        <p className='mb-2 text-[14px] font-medium text-primary-deep'>
          急速充電時単価[円/分]*
        </p>
        <Input
          id='rapidChargeUnitPrice'
          type='number'
          step='0.01'
          {...props.register("rapidChargeUnitPrice" as Path<T>)}
          className='w-full'
        />
        {props.errors.rapidChargeUnitPrice && (
          <p className='absolute text-[12px] text-error'>
            {props.errors.rapidChargeUnitPrice.message}
          </p>
        )}
      </div>
      <div className='w-1/3'>
        <p className='mb-2 text-[14px] font-medium text-primary-deep'>
          基礎充電時単価[円/分]*
        </p>
        <Input
          id='basicChargeUnitPrice'
          type='number'
          step='0.01'
          {...props.register("basicChargeUnitPrice" as Path<T>)}
          className='w-full'
        />
        {props.errors.basicChargeUnitPrice && (
          <p className='absolute text-[12px] text-error'>
            {props.errors.basicChargeUnitPrice.message}
          </p>
        )}
      </div>
    </div>
  );
};

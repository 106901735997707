import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useRecoilState } from "recoil";

import { Result } from "@/components/visialization";
import { Species } from "@/constants";
import { useAuth, useToast } from "@/hooks";
import { useLoading } from "@/hooks/useLoading";
import { paramState } from "@/stores/atoms/paramAtom";
import { simulationState } from "@/stores/atoms/simulationAtom";
import { Param } from "@/types/selector";
import { apiPost } from "@/utils/api";

export const useVisualizationPage = () => {
  const navigate = useNavigate();
  const { error } = useToast();
  const {
    setTargetValue,
    progressValue,
    size,
    radius,
    circumference,
    progressCircle,
    msg,
  } = useLoading();
  const { company } = useAuth();

  const [localStrageParam] = useState<string | null>(
    localStorage.getItem("params"),
  );
  const [param, setParam] = useRecoilState(paramState);
  const [simulation, setSimulation] = useRecoilState(simulationState);

  const [isDisplayLoading, setIsDisplayLoading] = useState(false);

  /**
   * ローディング時間の計算
   */
  useMemo(() => {
    let roupNum = 1;
    let reqTime = 13;
    const intervalTime = 3;
    const processTime = 3;

    switch (param?.species) {
      case Species.TRIP:
        roupNum = param.trips?.length ?? 1;
        if (roupNum === 1) reqTime = 8;
        else if (roupNum === 2) reqTime = 12;
        else if (roupNum === 3) reqTime = 16;
        break;
      case Species.DAYLY_REPORT:
        roupNum = param.dailyReports?.length ?? 1;
        break;
      case Species.ROUTE:
        roupNum = param.dailyReports?.length ?? 1;
        reqTime = 8;
        break;
    }

    return setTargetValue(roupNum * (reqTime + intervalTime + processTime) * 2);
  }, [param]);

  /**
   * パラメータ処理
   * @param local string
   */
  const handleSession = async (local: string) => {
    const params = JSON.parse(local);
    const species = localStorage.getItem("species");
    params.species = species && parseInt(species);
    params.is_debug = company?.is_debug;
    setParam(params);

    return await getEffectivness(params);
  };

  /**
   * シミュレーション結果の取得
   */
  const getEffectivness = async (params: Param) => {
    if (isDisplayLoading) return;
    setIsDisplayLoading(true);

    let errMsg = null;
    const defaultErrMsg = "シミュレーション結果の取得に失敗しました";

    const res = await apiPost<Result>(
      `/api/simulation/effectiveness/${params.species}`,
      params,
    );

    switch (res.code) {
      case 200:
        if (res.data && res.data?.detail.total.length > 0)
          setSimulation(res.data);
        else if (res.data?.detail.total.length === 0)
          errMsg = "該当するデータがありません";
        break;
      case 429:
        errMsg = "リクエスト回数が多すぎます";
        break;
      case 504:
        errMsg = "タイムアウトしました";
        break;
      default:
        errMsg = defaultErrMsg;
        break;
    }

    if (errMsg) {
      console.error(res);
      error(errMsg);
      navigate("/simulation/effectiveness");
    }

    return setIsDisplayLoading(false);
  };

  useEffect(() => {
    if (param && simulation) return;
    if (!localStrageParam) {
      error("セッションが切れています");
      return navigate("/simulation/effectiveness");
    }

    handleSession(localStrageParam);
  }, []);

  return {
    param,
    simulation,
    isDisplayLoading,
    size,
    radius,
    circumference,
    progressValue,
    progressCircle,
    msg,
  };
};

import { useCallback } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";

import { DefaultSimulationValues, InitialRoute } from "@/components/simulation";
import { Paths, Species } from "@/constants";
import { openWindowWithSession } from "@/utils/window";

const routeSchema = yup.object({
  displacement: yup
    .number()
    .transform((value: number) => (isNaN(value) ? null : value))
    .required((value) => typeof value === "number"),
  vehicleWidth: yup
    .number()
    .transform((value: number) => (isNaN(value) ? null : value))
    .required((value) => typeof value === "number"),
  vehicleHeight: yup
    .number()
    .transform((value: number) => (isNaN(value) ? null : value))
    .required((value) => typeof value === "number"),
  vehicleWeight: yup
    .number()
    .transform((value: number) => (isNaN(value) ? null : value))
    .required((value) => typeof value === "number"),
  vehicleModel: yup.string().transform((value) => (value ? String(value) : "")),
  classificationDivisionNumber: yup
    .string()
    .transform((value) => (value ? String(value) : "")),
  batteryCapacity: yup
    .number()
    .nullable()
    .transform((value: number) => (isNaN(value) ? null : value)),
  idlingStop: yup.boolean().transform((value) => !!value),
  leavingFromLatitude: yup
    .number()
    .transform((value: number) => (isNaN(value) ? null : value))
    .required((value) => typeof value === "number"),
  leavingFromLongitude: yup
    .number()
    .transform((value: number) => (isNaN(value) ? null : value))
    .required((value) => typeof value === "number"),
  arrivingToLatitude: yup
    .number()
    .transform((value: number) => (isNaN(value) ? null : value))
    .required((value) => typeof value === "number"),
  arrivingToLongitude: yup
    .number()
    .transform((value: number) => (isNaN(value) ? null : value))
    .required((value) => typeof value === "number"),
});

const routeFormSchema = yup.object({
  gasolineUnitPrice: yup
    .number()
    .transform((value: number) => (isNaN(value) ? null : value))
    .required((value) => typeof value === "number"),
  rapidChargeUnitPrice: yup
    .number()
    .transform((value: number) => (isNaN(value) ? null : value))
    .required((value) => typeof value === "number"),
  basicChargeUnitPrice: yup
    .number()
    .transform((value: number) => (isNaN(value) ? null : value))
    .required((value) => typeof value === "number"),
  routes: yup
    .array()
    .of(routeSchema)
    .required((value) => Array.isArray(value) && value.length > 0),
});

export type RouteFormInput = yup.InferType<typeof routeFormSchema>;

export const useRouteSimulation = () => {
  const {
    control,
    formState: { errors, isDirty, isValid },
    register,
    watch,
    handleSubmit,
    setValue,
    getValues,
    trigger,
  } = useForm<RouteFormInput>({
    defaultValues: {
      gasolineUnitPrice: DefaultSimulationValues.gasolineUnitPrice,
      rapidChargeUnitPrice: DefaultSimulationValues.rapidChargeUnitPrice,
      basicChargeUnitPrice: DefaultSimulationValues.basicChargeUnitPrice,
      routes: [InitialRoute],
    },
    resolver: yupResolver(routeFormSchema),
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const { fields } = useFieldArray({
    control,
    name: "routes",
  });

  /**
   * 緯度と経度をセット
   */
  const setLatLngs = async (tab: number, lat: number, lng: number) => {
    if (tab === 1) {
      setValue("routes.0.leavingFromLatitude", lat);
      setValue("routes.0.leavingFromLongitude", lng);
      return trigger([
        "routes.0.leavingFromLatitude",
        "routes.0.leavingFromLongitude",
      ]);
    }

    setValue("routes.0.arrivingToLatitude", lat);
    setValue("routes.0.arrivingToLongitude", lng);
    return trigger([
      "routes.0.arrivingToLatitude",
      "routes.0.arrivingToLongitude",
    ]);
  };

  /**
   * シミュレーション結果画面へ遷移
   */
  const simulation = handleSubmit((data) => {
    openWindowWithSession(Paths.RESULT, data, Species.ROUTE);
  });

  return {
    control,
    fields,
    errors,
    watch,
    register,
    setValue,
    getValues,
    setLatLngs,
    isDirty,
    isValid,
    trigger,
    simulation: useCallback(simulation, []),
  };
};

export const SpeciesMap = new Map([
  [1, "ID検索"],
  [2, "日報検索"],
  [3, "ルート検索"],
]);

export const Species = {
  TRIP: 1,
  DAYLY_REPORT: 2,
  ROUTE: 3,
};

import { ButtonHTMLAttributes } from "react";

import { AiOutlineDownload } from "react-icons/ai";

type ButtonTextProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonCsv = ({
  type,
  disabled = false,
  children,
  ...rest
}: ButtonTextProps) => {
  return (
    <button
      type={type}
      className={`flex cursor-pointer items-center  justify-center rounded-md px-2 pb-0.5 pt-1 text-[12px] ${
        disabled
          ? "cursor-context-menu bg-black-light text-white"
          : "bg-primary-opacity text-primary-light hover:text-primary-thin"
      }`}
      disabled={disabled}
      {...rest}
    >
      <AiOutlineDownload className='mr-1.5 h-6 w-6 pb-[5px]' />
      {children}
    </button>
  );
};

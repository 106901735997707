import { ComponentPropsWithoutRef, forwardRef } from "react";

export const LabelDirection = {
  None: "NONE",
  Top: "TOP",
} as const;

type LabelDirection = (typeof LabelDirection)[keyof typeof LabelDirection];

type InputProps = ComponentPropsWithoutRef<"input"> & {
  labelDirection?: LabelDirection;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ labelDirection = LabelDirection.Top, children, ...rest }, ref) => {
    switch (labelDirection) {
      case LabelDirection.Top:
        return children ? (
          <label htmlFor={rest?.id ?? ""} className='flex flex-col'>
            <span className='mb-2 text-[14px] font-medium text-primary-deep'>
              {children}
            </span>
            <BaseInput ref={ref} {...rest} />
          </label>
        ) : (
          <BaseInput ref={ref} {...rest} />
        );
      default:
        return <BaseInput ref={ref} {...rest} />;
    }
  },
);

Input.displayName = "Input";

const BaseInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      type,
      autoComplete = "off",
      disabled = false,
      className = "",
      ...rest
    },
    ref,
  ) => (
    <input
      id={id}
      ref={ref}
      className={`w-[248px] rounded-[2px] border border-black-light p-[4px_12px] text-[14px] placeholder-black-light ${className}`}
      type={type}
      autoComplete={autoComplete}
      disabled={disabled}
      {...rest}
    />
  ),
);

BaseInput.displayName = "BaseInput";

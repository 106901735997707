import { useEffectivenessVisualization } from "@/components/visialization/hooks";
import { Species, SpeciesMap } from "@/constants";

export const SearchCondition = () => {
  const { params, trips, dailyReports, route } =
    useEffectivenessVisualization();

  if (!params) return <></>;

  return (
    <div className='rounded-md border p-4 text-[12px]'>
      <p className='mb-2'>検索条件: {SpeciesMap.get(params.species)}</p>
      <div className='mx-4'>
        <div className='mb-2'>
          <span>ガソリン単価[円]: {params.gasolineUnitPrice}、</span>
          <span>急速充電時単価[円/分]: {params.rapidChargeUnitPrice}、</span>
          <span>基礎充電時単価[円/分]: {params.basicChargeUnitPrice}</span>
        </div>

        {params.species === Species.TRIP && (
          <div>
            {trips?.map((item, index) => {
              return (
                <div key={index}>
                  <span>ドライバーid: {item.driverId}、</span>
                  <span>開始日時: {item.timestampMin}、</span>
                  <span>終了日時: {item.timestampMax}</span>
                </div>
              );
            })}
          </div>
        )}

        {params.species === Species.DAYLY_REPORT && (
          <div>
            {dailyReports?.map((item, index) => {
              return (
                <div key={index} className='mb-2'>
                  <span>出発地: {item.leavingFrom}、</span>
                  <span>目的地: {item.arrivingTo}、</span>
                  <span>電池容量: {item.batteryCapacity}、</span>
                  <span>
                    類型区分番号: {item.classificationDivisionNumber}、
                  </span>
                  <span>排気量: {item.displacement}、</span>
                  <span>アイドリングストップの有無: {item.idlingStop}、</span>
                  <span>出発時刻: {item.leavingAt}、</span>
                  <span>車両形式: {item.vehicleModel}、</span>
                  <span>車高[cm]: {item.vehicleHeight}、</span>
                  <span>車量[kg]: {item.vehicleWeight}、</span>
                  <span>車幅[cm]: {item.vehicleWidth}、</span>
                  <span>
                    {item.stopOvers.map((stopOver, index) => (
                      <span key={index}>
                        <span>立ち寄り地: {stopOver.address}、</span>
                        <span>
                          立ち寄り地の滞在時間[秒]: {stopOver.stayingSeconds}、
                        </span>
                      </span>
                    ))}
                  </span>
                </div>
              );
            })}
          </div>
        )}

        {params.species === Species.ROUTE && (
          <div>
            {route?.map((item, index) => {
              return (
                <div key={index} className='mb-2'>
                  <span>
                    出発地:{" "}
                    {item.leavingFromLatitude +
                      ", " +
                      item.leavingFromLongitude}
                    、
                  </span>
                  <span>
                    目的地:{" "}
                    {item.arrivingToLatitude + ", " + item.arrivingToLongitude}
                    、
                  </span>
                  <span>電池容量: {item.batteryCapacity}、</span>
                  <span>
                    類型区分番号: {item.classificationDivisionNumber}、
                  </span>
                  <span>排気量: {item.displacement}、</span>
                  <span>アイドリングストップの有無: {item.idlingStop}、</span>
                  <span>出発時刻: {item.leavingAt}、</span>
                  <span>車両形式: {item.vehicleModel}、</span>
                  <span>車高[cm]: {item.vehicleHeight}、</span>
                  <span>車重[kg]: {item.vehicleWeight}、</span>
                  <span>車幅[cm]: {item.vehicleWidth}、</span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchCondition;

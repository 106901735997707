import { BrowserRouter, Route, Routes } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";

import { Paths } from "@/constants";
import { Login, Effectiveness, Result } from "@/pages";
import "@/App.css";
import { Authentication, GuestRoute, Layout, PrivateRoute } from "@/providers";

function App() {
  return (
    <Authentication>
      <BrowserRouter>
        <RecoilRoot>
          <ToastContainer />
          <Layout>
            <Routes>
              <Route
                path={Paths.HOME}
                element={
                  <PrivateRoute>
                    <Effectiveness />
                  </PrivateRoute>
                }
              />
              <Route
                path={Paths.RESULT}
                element={
                  <PrivateRoute>
                    <Result />
                  </PrivateRoute>
                }
              />
              <Route
                path={Paths.LOGIN}
                element={
                  <GuestRoute>
                    <Login />
                  </GuestRoute>
                }
              />
            </Routes>
          </Layout>
        </RecoilRoot>
      </BrowserRouter>
    </Authentication>
  );
}

export default App;

import { useEffect, useState } from "react";

import { useRecoilState } from "recoil";

import { Species } from "@/constants";
import { paramState } from "@/stores/atoms/paramAtom";
import { DailyReport, Route, Trip } from "@/types/selector";

export const useEffectivenessVisualization = () => {
  const [params] = useRecoilState(paramState);
  const [trips, setTrips] = useState<Trip[]>();
  const [dailyReports, setDailyReports] = useState<DailyReport[]>();
  const [route, setRoute] = useState<Route[]>();

  /**
   * 種別をもとにパラメータをセット
   */
  useEffect(() => {
    if (!params) return;

    switch (params.species) {
      case Species.TRIP:
        setTrips(params.trips);
        break;
      case Species.DAYLY_REPORT:
        setDailyReports(params.dailyReports);
        break;
      case Species.ROUTE:
        setRoute(params.routes);
        break;
    }
  }, [params]);

  return {
    params,
    trips,
    dailyReports,
    route,
  };
};

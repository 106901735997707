import { IoMdOpen } from "react-icons/io";

import { Button } from "@/components/parts";
import {
  UnitPriceInput,
  useRouteSimulation,
  Map,
  RouteRow,
} from "@/components/simulation";

export const RouteForm = () => {
  const {
    control,
    fields,
    errors,
    isDirty,
    isValid,
    register,
    simulation,
    setLatLngs,
  } = useRouteSimulation();

  return (
    <div>
      <div className='w-[68%]'>
        <UnitPriceInput register={register} errors={errors} />
      </div>
      <div className='w-full'>
        <div className='flex'>
          <div className='mb-6 flex w-1/2 flex-col gap-y-4'>
            {fields.map((field, index) => (
              <RouteRow
                key={field.id}
                index={index}
                control={control}
                errors={errors}
              />
            ))}
          </div>
          <div className='ml-4 w-1/2'>
            <Map setLatLngs={setLatLngs} />
          </div>
        </div>
        <div>
          <Button
            type='submit'
            Icon={IoMdOpen}
            className='mt-16 w-full'
            disabled={!(isDirty && isValid)}
            onClick={simulation}
          >
            シミュレーション結果を表示する
          </Button>
        </div>
      </div>
    </div>
  );
};

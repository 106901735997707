import { Tabs } from "flowbite-react";

import { TripForm, DailyReportForm, RouteForm } from "@/components/simulation";

export function Effectiveness() {
  return (
    <Tabs style='underline'>
      <Tabs.Item active title='ID検索'>
        <div className='my-4'>
          <TripForm />
        </div>
      </Tabs.Item>
      <Tabs.Item title='日報検索'>
        <div className='my-4'>
          <DailyReportForm />
        </div>
      </Tabs.Item>
      <Tabs.Item title='ルート検索'>
        <div className='my-4'>
          <RouteForm />
        </div>
      </Tabs.Item>
    </Tabs>
  );
}

import { IoMdOpen, IoIosAddCircle } from "react-icons/io";

import { Button, ButtonText } from "@/components/parts";
import {
  MaxTripCount,
  TripRow,
  UnitPriceInput,
  useTripSimulation,
} from "@/components/simulation";

export const TripForm = () => {
  const {
    drivers,
    control,
    fields,
    errors,
    canSimulate,
    register,
    addTrip,
    removeTrip,
    simulation,
  } = useTripSimulation();

  return (
    <div className='h-full'>
      <div className='w-[68%]'>
        <UnitPriceInput register={register} errors={errors} />
      </div>
      <div className='w-full'>
        {fields.map((field, index) => (
          <div className='mb-4 w-full' key={field.id}>
            <TripRow
              drivers={drivers}
              control={control}
              isDisplayDelete={index >= 1 && index + 1 === fields.length}
              index={index}
              errors={errors}
              onDelete={removeTrip}
            />
            <div></div>
          </div>
        ))}
        <div className='mb-16 flex justify-center'>
          <ButtonText
            Icon={IoIosAddCircle}
            onClick={addTrip}
            className='w-fit'
            disabled={fields.length >= MaxTripCount}
          >
            ドライバーを追加
          </ButtonText>
        </div>
        <div>
          <Button
            Icon={IoMdOpen}
            onClick={simulation}
            className='w-full'
            disabled={!canSimulate}
          >
            シミュレーション結果を表示する
          </Button>
        </div>
      </div>
    </div>
  );
};

import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  LabelList,
} from "recharts";

import { Unit } from "@/components/visialization/types";

type ChartProps = {
  diff: number;
  data: Unit[];
  unit: string;
  label: string;
  keys: string[];
};

export const ChartBar = ({ diff, data, unit, label, keys }: ChartProps) => {
  if( label === "コスト[円]"){
    return (
      <div>
        <p className='w-ful mb-2 text-center text-[16px] text-primary-deep'>
          {label}
        </p>
        <ResponsiveContainer width='100%' height={200}>
          <BarChart data={data} layout='vertical'>
            <XAxis
              type='number'
              padding={{ right: 50, left: 50 }}
              fontSize={14}
              tickFormatter={(value) => `${value.toLocaleString( undefined, { maximumFractionDigits: 0 } )}`}
            />
            <YAxis dataKey='name' type='category' fontSize={20} />
            <Tooltip formatter={(value) => `${value.toLocaleString( undefined, { maximumFractionDigits: 0 } ) + unit}`} />
            <CartesianGrid strokeDasharray='3 3' />
            <Bar dataKey='value' barSize={50}>
              {data.map((entry, index: number) => (
                <Cell
                  key={index}
                  fill={entry.name === keys[0] ? "#989FB0" : "#005599"}
                />
              ))}
              <LabelList
                dataKey='value'
                position='right'
                style={{ fill: "black" }}
                formatter={(value: number) => `${value.toLocaleString( undefined, { maximumFractionDigits: 0 } ) + unit}`}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <div className='mt-4'>
          <p className='w-full text-center text-error'>
            <span className='font-bold'>{diff.toLocaleString( undefined, { maximumFractionDigits: 0 } ) + unit}</span>削減
          </p>
        </div>
      </div>
    );
  } else{
    return (
      <div>
        <p className='w-ful mb-2 text-center text-[16px] text-primary-deep'>
          {label}
        </p>
        <ResponsiveContainer width='100%' height={200}>
          <BarChart data={data} layout='vertical'>
            <XAxis
              type='number'
              padding={{ right: 50, left: 50 }}
              fontSize={14}
              tickFormatter={(value) => `${value.toLocaleString()}`}
            />
            <YAxis dataKey='name' type='category' fontSize={20} />
            <Tooltip formatter={(value) => `${value.toLocaleString() + unit}`} />
            <CartesianGrid strokeDasharray='3 3' />
            <Bar dataKey='value' barSize={50}>
              {data.map((entry, index: number) => (
                <Cell
                  key={index}
                  fill={entry.name === keys[0] ? "#989FB0" : "#005599"}
                />
              ))}
              <LabelList
                dataKey='value'
                position='right'
                style={{ fill: "black" }}
                formatter={(value: number) => `${value.toLocaleString() + unit}`}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <div className='mt-4'>
          <p className='w-full text-center text-error'>
            <span className='font-bold'>{diff.toLocaleString() + unit}</span>削減
          </p>
        </div>
      </div>
    );
  }
};

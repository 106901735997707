import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { Paths } from "@/constants";
import { useAuth } from "@/hooks";

const LoginSchema = yup.object({
  code: yup.string().required("IDを入力してください"),
  password: yup.string().required("パスワードを入力してください"),
});

type LoginInput = yup.InferType<typeof LoginSchema>;

export const useLoginPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const company = useAuth();
  const navigate = useNavigate();
  const {
    register,
    getValues,
    setError,
    trigger,
    formState: { errors },
  } = useForm<LoginInput>({
    defaultValues: {
      code: "",
      password: "",
    },
    resolver: yupResolver(LoginSchema),
  });

  const login = async () => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    const isValid = await trigger();
    if (!isValid) return setIsSubmitting(false);

    const input = getValues();
    const response = await company.login(input.code, input.password);
    setIsSubmitting(false);

    if (!response.success)
      return setError("root", { message: response.message ?? "" });

    navigate(Paths.HOME, { replace: true });
  };

  return {
    isSubmitting,
    errors,
    register,
    login: useCallback(login, []),
  };
};

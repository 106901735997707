import axios from "axios";

import { Paths } from "@/constants";

const api = axios.create({
  responseType: "json",
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: "application/json",
  },
  withCredentials: true,
  withXSRFToken: true,
});

export type SuccessResDataObjectType<T, U = unknown> = {
  data: T;
  success: boolean;
  code: number;
  message?: string;
  errors?: U;
};

export type FailedResErrorObjectType<U = unknown> = {
  data: null;
  success: boolean;
  code: number;
  message: string;
  errors?: U;
};

export type ApiResponse<T, U = unknown> =
  | SuccessResDataObjectType<T, U>
  | FailedResErrorObjectType<U>;

const syncApi = async <T, U>(
  method: "get" | "post" | "patch" | "delete",
  url: string,
  params = {},
  headers = {},
): Promise<ApiResponse<T, U>> => {
  try {
    const { data, status } = await api[method]<{ data: T }>(
      url,
      params,
      headers,
    );
    return {
      data: data.data,
      success: true,
      code: status,
    };
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const errorStatus =
        error.response?.data.code ?? error.response?.status ?? error.status;

      if (errorStatus === 401 && window.location.pathname !== Paths.LOGIN) {
        window.location.href = Paths.LOGIN;
      }

      return {
        data: null,
        success: false,
        code: error.response?.status ?? 500,
        message: error.response?.data.message,
        errors: error.response?.data.errors as U,
      };
    } else {
      return {
        data: null,
        success: false,
        code: 500,
        message: "Internal Server Error",
      };
    }
  }
};

export const apiGet = async <T, U = unknown>(url: string, params = {}) => {
  return await syncApi<T, U>(
    "get",
    `${process.env.REACT_APP_API_BASE_URL}` + url,
    params,
  );
};

export const apiPost = async <T, U = unknown>(url: string, params = {}) => {
  return await syncApi<T, U>(
    "post",
    `${process.env.REACT_APP_API_BASE_URL}` + url,
    params,
  );
};

export const apiPatch = async <T, U = unknown>(url: string, params = {}) => {
  return await syncApi<T, U>(
    "patch",
    `${process.env.REACT_APP_API_BASE_URL}` + url,
    params,
  );
};

export const apiDelete = async <T, U = unknown>(url: string, params = {}) => {
  return await syncApi<T, U>(
    "delete",
    `${process.env.REACT_APP_API_BASE_URL}` + url,
    { data: params },
  );
};

export const apiFile = async <T, U = unknown>(url: string, params = {}) => {
  return await syncApi<T, U>(
    "post",
    `${process.env.REACT_APP_API_BASE_URL}` + url,
    params,
    {
      headers: {
        "content-type": "multipart/form-data",
        "X-HTTP-Method-Override": "POST",
        "X-Requested-With": "XMLHttpRequest",
      },
      withCredentials: true,
    },
  );
};

export const apiGetExternalUrl = async (url: string, params = {}) => {
  return await axios.get(url, { params: params });
};

import { ChartBar } from "@/components/visialization/parts";
import { Total } from "@/components/visialization/types";

type Props = {
  title: string;
  total: Total;
};

export const ComparisonChart = ({ title, total }: Props) => {
  return (
    <div className='z-10 rounded-md border p-4 text-primary-deep'>
      <div>
        <p className='text-[20px] font-bold '>{title}</p>
      </div>
      <div className='z-50 m-4 flex'>
        <div className='w-1/2 p-4'>
          <ChartBar
            diff={total.diff.co2}
            data={total.co2}
            unit={"kg"}
            label={"CO₂排出量[kg]"}
            keys={["ICE", "EV"]}
          />
        </div>
        <div className='w-1/2 p-4'>
          <ChartBar
            diff={total.diff.cost}
            data={total.cost}
            unit={"円"}
            label={"コスト[円]"}
            keys={["ICE", "EV"]}
          />
        </div>
      </div>
    </div>
  );
};

export default ComparisonChart;

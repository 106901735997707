import { ButtonHTMLAttributes } from "react";

import { IconType } from "react-icons";

type ButtonTextProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  Icon?: IconType;
};

export const ButtonText = ({
  type,
  disabled = false,
  className = "",
  Icon,
  children,
  ...rest
}: ButtonTextProps) => {
  return (
    <button
      type={type}
      className={`Zpy-2.5  flex cursor-pointer justify-center  rounded-md bg-white text-[16px] ${className} ${
        disabled
          ? "cursor-auto text-black-light "
          : "text-primary-dark hover:text-primary-opacity"
      }`}
      disabled={disabled}
      {...rest}
    >
      {Icon && <Icon className='mr-1.5 mt-[2px] h-5 w-5' />}
      {children}
    </button>
  );
};

type Props = {
  progressValue: number;
  size: number;
  radius: number;
  circumference: number;
  progressCircle: number;
  msg: string;
};

export const Loading = ({
  progressValue,
  size,
  radius,
  circumference,
  progressCircle,
  msg,
}: Props) => {
  return (
    <div className='flex h-full w-full items-center justify-center text-center'>
      <div className='absolute right-0 top-0 h-screen w-screen bg-[#000000] bg-opacity-70'></div>
      <div className='h-250px w-[250px]'>
        <svg
          viewBox={`0 0 ${size} ${size}`}
          style={{ transform: "rotate(-90deg)" }}
        >
          <circle
            r={radius}
            cx={size / 2}
            cy={size / 2}
            stroke='#083B87'
            strokeWidth='5'
            fill='none'
            strokeLinecap='round'
            strokeDasharray={circumference}
            strokeDashoffset={progressCircle}
            className='bg-opacity-80'
          />
        </svg>
      </div>
      <div className='absolute mt-[-40px] text-white'>
        <p className='text-[20px] font-bold'>Loading....</p>
      </div>
      <div className='absolute mt-8 flex text-white'>
        <p>{progressValue}%</p>
      </div>
      <div className='absolute mt-80 flex text-white'>
        <p>{msg}</p>
      </div>
    </div>
  );
};

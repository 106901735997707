import { Diff } from "@/components/visialization/types";

type Props = {
  diff: Diff;
};

export const Summary = ({ diff }: Props) => {
  return (
    <div className='rounded-md border p-4 pb-3 text-[26px] text-primary-deep'>
      <p>
        車両をEVに切り替えることで、
        <span className=' text-error'>
          CO₂排出量を
          <span className='font-bold'>{diff.co2.toLocaleString()}kg</span>
          削減、
        </span>
      </p>
      <p>
        普通充電器を導入する場合、
        <span className=' text-error'>
          コストを
          <span className='font-bold'>{diff.cost.toLocaleString( undefined, { maximumFractionDigits: 0 })}円</span>
          削減
        </span>
        できる見込みがあります。
      </p>
    </div>
  );
};

export default Summary;

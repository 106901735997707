import { Navigate } from "react-router-dom";

import { Paths } from "@/constants";
import { useAuth } from "@/hooks";

export const GuestRoute = ({ children }: { children: JSX.Element }) => {
  const { company } = useAuth();

  if (company) {
    return <Navigate to={Paths.HOME} />;
  }

  return children;
};

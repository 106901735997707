import { useEffect, useMemo, useState } from "react";

const size = 120;
const radius = 50;
const circumference = 2 * Math.PI * radius; // 円周
const interval = 500; // 0.5秒

export const useLoading = () => {
  const [targetValue, setTargetValue] = useState<number>(0);
  const [progressValue, setProgressValue] = useState(0);
  const [progressCircle, setProgressCircle] = useState<number>(circumference);
  const [msg, setMsg] = useState(
    "ブラウザを閉じたり再実行等の操作はせずにそのままお待ちください",
  );

  /**
   * プログレスサークルの進度計算
   */
  const calculateProgressCircle = useMemo(
    () => circumference * ((100 - progressValue) * 0.01),
    [progressValue],
  );

  /**
   * 1秒ごとに進度の計算
   */
  useEffect(() => {
    if (!targetValue) return;

    const handleCalculate = () => {
      const calculateProgressValue =
        Math.round((progressValue + (1 / targetValue) * 100) * 10) / 10;

      setProgressValue(
        calculateProgressValue > 100 ? 100 : calculateProgressValue,
      );
      setProgressCircle(calculateProgressCircle);
    };

    if (progressValue >= 100) return setProgressCircle(0);
    const intervalId = setInterval(handleCalculate, interval);

    return () => clearInterval(intervalId);
  }, [targetValue, progressValue]);

  useEffect(() => {
    if (progressCircle === 0) return setMsg("まもなく処理が完了します");
  }, [progressCircle]);

  return {
    targetValue,
    progressValue,
    size,
    radius,
    circumference,
    progressCircle,
    setTargetValue,
    msg,
  };
};

import { IoMdOpen } from "react-icons/io";

import { Button, ButtonCsv } from "@/components/parts";
import {
  DailyReportRow,
  UnitPriceInput,
  useDailyReportSimulation,
} from "@/components/simulation";

export const DailyReportForm = () => {
  const {
    control,
    fields,
    errors,
    fileRef,
    canSimulate,
    register,
    importCsvFile,
    loadCsvFile,
    simulation,
  } = useDailyReportSimulation();

  return (
    <form className='h-full' onSubmit={simulation}>
      <div className='w-[68%]'>
        <UnitPriceInput register={register} errors={errors} />
      </div>
      <div className='w-full'>
        <div className='mb-4 flex items-center justify-end'>
          <a
            className='mx-2  mr-4 h-fit cursor-pointer border-b border-primary-light text-[12px] text-primary-light hover:border-primary-thin hover:text-primary-thin'
            href='/file/daily_report_sample.csv'
            download
          >
            サンプルファイルをダウンロード
          </a>
          <ButtonCsv type='button' onClick={importCsvFile}>
            CSVファイルからインポート
          </ButtonCsv>
          <input
            ref={fileRef}
            type='file'
            accept='text/csv'
            className='hidden'
            onChange={loadCsvFile}
          />
        </div>
        <div className='mb-6 flex w-full flex-col gap-y-4'>
          {fields.map((field, index) => (
            <DailyReportRow
              key={field.id}
              index={index}
              control={control}
              field={field}
              errors={errors}
            />
          ))}
        </div>
        <div>
          <Button
            type='submit'
            Icon={IoMdOpen}
            className='mt-16 w-full'
            disabled={!canSimulate}
          >
            シミュレーション結果を表示する
          </Button>
        </div>
      </div>
    </form>
  );
};

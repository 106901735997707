import { Tabs } from "flowbite-react";

import { Loading } from "@/components/layouts";
import {
  ComparisonChart,
  ComparisonTable,
  SearchCondition,
  Summary,
  SupplementaryText,
} from "@/components/visialization/effectiveness";
import { SpeciesMap } from "@/constants";
import { useVisualizationPage } from "@/pages/hooks";

export function Result() {
  const {
    param,
    simulation,
    isDisplayLoading,
    size,
    radius,
    circumference,
    progressValue,
    progressCircle,
    msg,
  } = useVisualizationPage();

  if (isDisplayLoading)
    return (
      <Loading
        progressValue={progressValue}
        size={size}
        radius={radius}
        circumference={circumference}
        progressCircle={progressCircle}
        msg={msg}
      />
    );

  if (!simulation) return <></>;

  return (
    <div>
      <div>
        <p className='text-[20px] font-bold text-primary-deep'>
          シミュレーション結果： {param && SpeciesMap.get(param.species)}
        </p>
      </div>
      <div className='mt-6'>
        <Tabs style='underline'>
          <Tabs.Item active title='全て'>
            <div className='my-4  min-w-[1000px]'>
              <div>
                <Summary diff={simulation.total.diff} />
              </div>
              <div className='mt-8'>
                <ComparisonChart
                  title='EV切り替え後のCO₂排出量とコストの比較'
                  total={simulation.total}
                />
              </div>
              <div className='mt-8'>
                <ComparisonTable result={simulation} />
              </div>
              <div className='mt-8'>
                <SupplementaryText params={param} />
              </div>
              <div className='mt-8'>
                <SearchCondition />
              </div>
            </div>
          </Tabs.Item>
          <Tabs.Item title='車両'>
            <div className='my-4'></div>
          </Tabs.Item>
          <Tabs.Item title='拠点'>
            <div className='my-4'></div>
          </Tabs.Item>
        </Tabs>
      </div>
    </div>
  );
}

import { useCallback, useRef, useState } from "react";

import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

interface LatLng {
  lat: number;
  lng: number;
}

const mapContainerStyle = {
  height: "375px",
  width: "100%",
};
const zoom = 14;
const options = {
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  zoomControl: true,
};
const initialLocation = {
  lat: 35.729724,
  lng: 139.74748,
};

type Props = {
  setLatLngs: (tab: number, lat: number, lng: number) => void;
};

export const Map = ({ setLatLngs }: Props) => {
  const mapRef = useRef<google.maps.Map | undefined>(undefined);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY as string,
  });
  const [tab, setTab] = useState(1);
  const [leavingFrom, setLeavingFrom] = useState<LatLng>({
    lat: 0,
    lng: 0,
  });
  const [arrivingTo, setArrivingTo] = useState<LatLng>({
    lat: 0,
    lng: 0,
  });

  const onMapClick = async (e: google.maps.MapMouseEvent) => {
    const latLng = {
      lat: e.latLng ? e.latLng.lat() : 0,
      lng: e.latLng ? e.latLng.lng() : 0,
    };
    if (tab === 1) {
      setLeavingFrom(latLng);
      setLatLngs(1, latLng.lat, latLng.lng);
      return;
    }

    setArrivingTo(latLng);
    setLatLngs(2, latLng.lat, latLng.lng);
  };

  const onMapLoad = useCallback(async (map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  if (loadError) return <div>Error</div>;

  if (!isLoaded) return <div>Loading</div>;

  return (
    <div>
      <GoogleMap
        id='google-map-component'
        mapContainerStyle={mapContainerStyle}
        center={initialLocation}
        zoom={zoom}
        options={options}
        onLoad={onMapLoad}
        onClick={onMapClick}
      >
        <Marker position={leavingFrom} />
        <Marker position={arrivingTo} />
      </GoogleMap>
      <div className='flex w-full justify-center'>
        <button
          className={`relative top-[-350px] h-fit w-fit cursor-pointer rounded-l-md px-3 pb-[4px] pt-[6px] text-[12px] ${
            tab === 1
              ? "bg-primary-main text-white"
              : "bg-white text-primary-deep"
          }`}
          onClick={() => setTab(1)}
        >
          出発地
        </button>
        <button
          className={`relative top-[-350px] h-fit w-fit cursor-pointer rounded-r-md px-3 pb-[4px] pt-[6px] text-[12px] ${
            tab === 2
              ? "bg-primary-main text-white"
              : "bg-white text-primary-deep"
          }`}
          onClick={() => setTab(2)}
        >
          目的地
        </button>
      </div>
    </div>
  );
};

import React from "react";

import { Control, Controller, FieldErrors } from "react-hook-form";
import { IoIosClose } from "react-icons/io";

import { SelectBox, TimePicker } from "@/components/parts";
import { TripFormInput } from "@/components/simulation";
import { Option } from "@/types";

type Props = {
  drivers: Option[] | null;
  control: Control<TripFormInput>;
  isDisplayDelete: boolean;
  index: number;
  errors: FieldErrors<TripFormInput>;
  onDelete: (index: number) => void;
};

export const TripRow: React.FC<Props> = (props: Props) => {
  const tripErrors = props?.errors?.trips?.[props.index] ?? null;

  if (!props.drivers) return <></>;

  return (
    <div className='relative rounded-md border-2 border-dashed p-6 pb-2 pt-4'>
      <div className='flex w-full justify-end'>
        {props.isDisplayDelete ? (
          <IoIosClose
            onClick={() => props.onDelete(props.index)}
            className=' h-7 w-7 cursor-pointer text-primary-deep hover:text-primary-opacity '
          />
        ) : (
          <div className='w-7'></div>
        )}
      </div>
      <div className='flex w-full '>
        <Controller
          control={props.control}
          name={`trips.${props.index}.driverId`}
          render={({ field: { onChange, value } }) => (
            <div className='mr-4 w-1/2'>
              <p className='mb-2 text-[14px] font-medium text-primary-deep'>
                ドライバー*
              </p>
              <SelectBox
                id='driverId'
                options={props.drivers as Option[]}
                className='w-full'
                value={value}
                change={(e) => onChange(e.id)}
              />
              {tripErrors?.driverId && (
                <p className='absolute text-[12px] text-error'>
                  {tripErrors.driverId.message}
                </p>
              )}
            </div>
          )}
        />
        <Controller
          control={props.control}
          name={`trips.${props.index}.timestampMin`}
          render={({ field: { onChange, value } }) => (
            <div className='min-w-1/4 mr-4'>
              <p className='mb-2 text-[14px] font-medium text-primary-deep'>
                開始日時*
              </p>
              <TimePicker defaultValue={value} setTimeStamp={onChange} />
              {tripErrors?.timestampMin && (
                <p className='absolute bottom-[5px] text-[12px] text-error'>
                  {tripErrors.timestampMin.message}
                </p>
              )}
            </div>
          )}
        />
        <Controller
          control={props.control}
          name={`trips.${props.index}.timestampMax`}
          render={({ field: { onChange, value } }) => (
            <div className='min-w-1/4'>
              <p className='mb-2 text-[14px] font-medium text-primary-deep'>
                終了日時*
              </p>
              <TimePicker defaultValue={value} setTimeStamp={onChange} />
              {tripErrors?.timestampMax && (
                <p className='absolute bottom-[5px] text-[12px] text-error'>
                  {tripErrors.timestampMax.message}
                </p>
              )}
            </div>
          )}
        />
      </div>
    </div>
  );
};

import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  useEffect,
  useState,
} from "react";

import { Option } from "@/types";

type SelectBoxProps = ComponentPropsWithoutRef<"select"> & {
  options: Option[];
  change: (selectedItem: Option) => void;
};

export const SelectBox = forwardRef<HTMLSelectElement, SelectBoxProps>(
  function Select(
    {
      id,
      autoComplete = "off",
      disabled = false,
      className = "",
      options = [],
      change,
      ...rest
    },
    ref,
  ) {
    const [lists, setLists] = useState(options);
    const [color, setColor] = useState("text-black-light");
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedIndex = event.target.selectedIndex;
      const selectedItem = lists[selectedIndex];
      change(selectedItem);
      if (selectedIndex !== 0) return setColor("text-black-deep");
      if (selectedIndex === 0) return setColor("text-black-light");
    };

    useEffect(() => {
      if (lists[0].id === 0) return;
      setLists((prev) => [{ id: 0, name: "選択してください" }, ...prev]);
    }, [options]);

    return (
      <select
        id={id}
        ref={ref}
        className={`w-[248px] rounded-[2px] border border-black-light p-[4px_12px] text-[14px] ${className} ${color}`}
        autoComplete={autoComplete}
        disabled={disabled}
        onChange={handleChange}
        {...rest}
      >
        {lists.map((option) => (
          <option
            key={option.id}
            value={option.id}
            className={`${
              option.id !== 0 ? "text-black-deep" : "text-black-light"
            }`}
          >
            {option.name}
          </option>
        ))}
      </select>
    );
  },
);

SelectBox.displayName = "SelectBox";

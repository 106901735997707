import { IoMdArrowDropdown } from "react-icons/io";

import { Result } from "@/components/visialization/types";

type HeaderProps = {
  label: string;
  className?: string;
};

type BodyProps = {
  value: string | number;
  className?: string;
};

type Props = {
  result: Result;
};

const Header = ({ label, className = "bg-white" }: HeaderProps) => {
  return (
    <th
      scope='col'
      className={`border-r px-3 py-3 text-left text-xs font-medium tracking-wider ${className}`}
    >
      {label}
    </th>
  );
};

const Body = ({ value, className = "bg-white" }: BodyProps) => {
  return (
    <td
      scope='col'
      className={`border-r px-2 py-3 text-right text-[14px] font-medium tracking-wider  ${className}`}
    >
      {value}
    </td>
  );
};

export const ComparisonTable = ({ result }: Props) => {
  const hClassIce = "text-white bg-black-main";
  const hClassEv = "text-white bg-primary-main";

  console.log(result);

  return (
    <div className='rounded-md border p-4'>
      <div className='mb-8 flex w-full text-left'>
        <p className='text-[20px] font-bold'>EV切り替え後の推定値</p>
      </div>
      <div className='flex w-full justify-center '>
        <div>
          <div className='mx-8 mb-0'>
            <div className='flex w-[310px] justify-end'>
              <p className='font mb-1'>ICE車</p>
            </div>
            <table className='mr-8 divide-y border border-gray-200'>
              <thead>
                <tr>
                  <Header label='走行距離' className='min-w-[130px]' />
                  <Header label='所要時間' className='min-w-[130px]' />
                  <Header
                    label='ガソリン消費量'
                    className={hClassIce + " min-w-[130px] border-l"}
                  />
                  <Header
                    label='燃費'
                    className={hClassIce + " min-w-[130px]"}
                  />
                  <Header
                    label='CO₂排出量'
                    className={hClassIce + " min-w-[130px]"}
                  />
                  <Header
                    label='ガソリン代'
                    className={hClassIce + " min-w-[130px]"}
                  />
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {result.detail.total.map((item, index) => (
                  <tr key={index}>
                    <Body value={item.length.toLocaleString( undefined, { maximumFractionDigits: 2 } ) + "km"} />
                    <Body value={item.requireTime.toLocaleString() + "h"} />
                    <Body
                      value={item.ice.energyConsumption.toLocaleString( undefined, { maximumFractionDigits: 2 } ) + "L"}
                    />
                    <Body
                      value={item.ice.fuelEfficiency.toLocaleString( undefined, { maximumFractionDigits: 2 } ) + "km/L"}
                    />
                    <Body
                      value={item.ice.co2Emissions.toLocaleString( undefined, { maximumFractionDigits: 2 } ) + "kg"}
                    />
                    <Body
                      value={"￥" + item.ice.energyPrice.toLocaleString( undefined, { maximumFractionDigits: 0 } )}
                    />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='mt-8 flex w-[900px] items-center justify-end'>
            <IoMdArrowDropdown className='h-32 w-32  text-black-opacity' />
            <div className='ml-4 flex h-[100px] w-fit items-center border border-dashed px-4 text-error'>
              <div className=''>
                <div className='flex'>
                  <p className='mr-4 w-fit'>co2排出量:</p>
                  <p className='w-[150px] text-right'>
                    <span className='w-fit font-bold'>
                      {result.total.diff.co2.toLocaleString() + "kg"}
                    </span>
                    削減
                  </p>
                </div>
                <div className='flex  justify-end'>
                  <p className='mr-4'>コスト:</p>
                  <p className='w-[150px] text-right'>
                    <span className='w-fit font-bold'>
                      {result.total.diff.cost.toLocaleString( undefined, { maximumFractionDigits: 0 } ) + "円"}
                    </span>
                    削減
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='flex w-[370px] justify-end'>
            <p className='font mb-1 mr-8'>EV車</p>
          </div>
          <div className='mb-8 flex w-[985px] justify-end'>
            <table className='mr-8 divide-y border border-gray-200'>
              <thead>
                <tr>
                  <Header
                    label='電力消費量'
                    className={hClassEv + " min-w-[130px]"}
                  />
                  <Header
                    label='電力消費率'
                    className={hClassEv + " min-w-[130px]"}
                  />
                  <Header
                    label='CO₂排出量'
                    className={hClassEv + " min-w-[130px]"}
                  />
                  <Header
                    label='電気代基礎充電'
                    className={hClassEv + " min-w-[130px]"}
                  />
                  <Header
                    label='電気代急速経路充電'
                    className={hClassEv + " min-w-[130px]"}
                  />
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-white'>
                {result.detail.total.map((item, index) => (
                  <tr key={index}>
                    <Body
                      value={(item.ev.energyConsumption/1000).toLocaleString( undefined, { maximumFractionDigits: 2 } ) + "kWh"}
                    />
                    <Body
                      value={item.ev.fuelEfficiency.toLocaleString( undefined, { maximumFractionDigits: 2 } ) + "km/kWh"}
                    />
                    <Body
                      value={(item.ev.co2Emissions).toLocaleString( undefined, { maximumFractionDigits: 2 } ) + "kg"}
                    />
                    <Body
                      value={
                        "￥" + item.ev.energyPrice.baseCharge.toLocaleString( undefined, { maximumFractionDigits: 0 } )
                      }
                    />
                    <Body
                      value={
                        "￥" +
                        item.ev.energyPrice.fastPathCharge.toLocaleString( undefined, { maximumFractionDigits: 0 } )
                      }
                    />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparisonTable;

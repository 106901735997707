import { useAuth } from "@/hooks";

export const Header = () => {
  const { company } = useAuth();

  return (
    <div className='flex h-fit shadow-md'>
      <div className='h-fit w-1/3 min-w-[300px] p-4'>
        <img src='/images/logo.png' className='h-[30px] w-[176px]'></img>
      </div>
      <div className='flex w-2/3 min-w-[150px] items-center justify-end p-4 font-bold text-primary-dark'>
        <p>{company?.name}</p>
      </div>
    </div>
  );
};

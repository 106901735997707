import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

import { Result } from "@/components/visialization";

const { persistAtom } = recoilPersist({
  key: "recoil-persist",
  storage: sessionStorage,
});

export const simulationState = atom<Result | null>({
  key: "simulation",
  default: null,
  effects_UNSTABLE: [persistAtom],
});
